import React, { useCallback, useEffect, useState } from "react"

// 🎨 UI
import { Box, Button, Portal } from "@chakra-ui/react"
import { useSelector } from "react-redux"

const ScrollTopButton = () => {
  // const { height, width } = useWindowDimensions()
  // Track whether the scroll arrow is needed.
  const [showScroll, setShowScroll] = useState(null)
  // Check the scroll state, re-memoize when scroll state changes.
  const checkScrollTop = useCallback(() => {
    const headerHeight = 400

    if (!showScroll && window.pageYOffset > headerHeight) {
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= headerHeight) {
      setShowScroll(false)
    }
  }, [showScroll])
  // Add/remove the event listener when the component is unmounted or the scroll state has changed.
  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop)
    return () => window.removeEventListener("scroll", checkScrollTop)
  }, [checkScrollTop])

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  const CURRENT_LANG = useSelector(state => state.currentLang)

  return (
    <Portal>
      <Box
        display={showScroll ? "block" : "none"}
        position="fixed"
        bottom="24px"
        right="16px"
      >
        <Button
          colorScheme="buttonSecondary"
          borderRadius="full"
          onClick={scrollTop}
        >
          {CURRENT_LANG === "en" ? "Scroll to top" : "Nach oben"}
          <span style={{ marginLeft: "4px", marginRight: "-3px" }}>↑</span>
        </Button>
      </Box>
    </Portal>
  )
}

export default ScrollTopButton
