import React, { useContext, useEffect, useState } from "react"
import SEO from "../../components/seo"

// 🎨 UI
import DetailPageWrapper from "../../components/Contents/DetailPageWrapper"
import TOCParagraphButton from "../../components/Buttons/TOCParagraphButton"
import ScrollTopButton from "../../components/ScrollTopButton"
import { Box, Heading, Text, VStack, Flex } from "@chakra-ui/react"
import { graphql } from "gatsby"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "../../components/Link"
import Transition from "../../components/Animation/Transition"
import { moveCameraToLayer } from "../../components/Animation/CameraAnimation"
import useGenUrl from "../../hooks/useGenUrl"
import { cleanStringForAnchor } from "../../tools/helper"

const ProjektzielListItem = props => {
  const CURRENT_LOCALE = useSelector(state => state.currentLocaleID)
  function stripHtml(html) {
    if (typeof document !== "undefined") {
      let tmp = document.createElement("DIV")
      tmp.innerHTML = html
      return tmp.textContent || tmp.innerText || ""
    } else return html
  }
  const projektziel = props.projektziel
  const schwerpunkt_slug =
    projektziel.acf_projektziel_content.groupLeft.relationMobilitaetsschwerpunkt
      ?.slug
  const path = useGenUrl(
    projektziel.slug,
    "projektziel",
    CURRENT_LOCALE,
    schwerpunkt_slug
  )
  return (
    <Link to={path}>
      <Box
        className={`pz_${schwerpunkt_slug}`}
        w="100%"
        p="2"
        border="1px solid"
        borderColor="gray.100"
        rounded="lg"
        position="relative"
        _hover={{
          transform: "translateY(-6px)",
          boxShadow: "lg",
          transition: "all 0.2s",
        }}
        transition="all 0.1s ease-out"
        mb="2"
      >
        <Heading
          fontSize="xl"
          fontWeight="600"
          dangerouslySetInnerHTML={{
            __html: projektziel?.title,
          }}
        ></Heading>
        {projektziel?.acf_projektziel_card?.textSummary !== null && (
          <Flex mt="2" w="full">
            <Text flex="1" isTruncated lineHeight="32px">
              {stripHtml(projektziel?.acf_projektziel_card?.textSummary)}
            </Text>
          </Flex>
        )}
      </Box>
    </Link>
  )
}

export default function Projektziele({ data, location }) {
  const sendActionToStore = useDispatch()
  const CURRENT_LANG = useSelector(state => state.currentLang)
  const CURRENT_PATH = useSelector(state => state.currentPath)

  const schwerpunkt_slugs = data.allWpSchwerpunkt.nodes

  const schwerpunkt_paragraphs = schwerpunkt_slugs.map((schwerpunkt, i) => {
    return { textSectionTitel: schwerpunkt.title }
  })

  const sortedProjektziele = []
  for (let i = 0; i < schwerpunkt_slugs.length; i++) {
    sortedProjektziele.push([])
  }

  const projektziele = data.allWpProjektziel.nodes.map((projektziel, i) => {
    let index = schwerpunkt_slugs.findIndex(
      s =>
        s.slug ===
        projektziel.acf_projektziel_content.groupLeft
          .relationMobilitaetsschwerpunkt.slug
    )
    if (index > -1) {
      sortedProjektziele[index].push(
        <ProjektzielListItem
          key={`${projektziel.slug}_${i}`}
          projektziel={projektziel}
        />
      )
    }

    return (
      <ProjektzielListItem
        key={`${projektziel.slug}_${i}`}
        projektziel={projektziel}
      />
    )
  })

  // 🚀 Transitions Settings
  const [exit, setExit] = useState(false)

  const defaultInAnimation = location?.state
    ? location.state.direction
    : "right"
  const [AnimationDirection, setAnimationDirection] =
    useState(defaultInAnimation)

  // 🚀🧨 Transitions Redux Triggers
  const TRANSITION_INFO = useSelector(state => state.transition)
  if (TRANSITION_INFO.shouldExit && TRANSITION_INFO.direction) {
    setAnimationDirection(TRANSITION_INFO.direction)
    setExit(TRANSITION_INFO.shouldExit)
    sendActionToStore({
      type: `PageChangeTransition/ended`,
    })
  }

  // * Zoom to project height and change background color to white
  useEffect(() => {
    moveCameraToLayer(sendActionToStore, "projektziele")
    sendActionToStore({
      type: `change/map/groundColor`,
      payload: "#edf2f7",
    })
  }, [])

  const sortedProjektzieleWithHeader = sortedProjektziele.map(
    (schwerpunkt, i) => {
      return (
        <Box key={`listItem_${schwerpunkt_slugs[i].slug}`} mb="16px">
          <Heading
            as="h1"
            id={cleanStringForAnchor(schwerpunkt_slugs[i].title)}
            pl="2"
            mb="4"
            size="lg"
          >
            {schwerpunkt_slugs[i].title}
          </Heading>
          {schwerpunkt}
        </Box>
      )
    }
  )

  return (
    <>
      <SEO title={"Projektziele"} />
      <ScrollTopButton />
      <Transition autoIn shouldExit={exit} direction={AnimationDirection}>
        <DetailPageWrapper>
          <Box width={["100%", "100%", "100%", "75%"]} maxWidth="920px" mb="4">
            <Heading size="md" pl="2" color="modeGray.500" mb="6">
              {CURRENT_LANG === "en" ? "Projectgoals" : "Projektziele"}
            </Heading>
          </Box>
          <VStack spacing="6" align="left" transition="all 0.2s">
            {sortedProjektzieleWithHeader}
          </VStack>
          <TOCParagraphButton
            paragraphs={schwerpunkt_paragraphs}
            currentPath={CURRENT_PATH}
            title={"Projektziele"}
          />
        </DetailPageWrapper>
      </Transition>
    </>
  )
}

export const query = graphql`
  query Projektziele($lang: String!) {
    allWpProjektziel(filter: { locale: { locale: { eq: $lang } } }) {
      nodes {
        title
        acf_projektziel_card {
          textSummary
        }
        acf_projektziel_content {
          groupRight {
            leitbegriffe {
              name
            }
          }
          groupLeft {
            relationMobilitaetsschwerpunkt {
              ... on WpSchwerpunkt {
                slug
                acf_schwerpunkt_content {
                  colorKartenFarbe
                }
              }
            }
          }
        }
        uri
        slug
      }
    }
    allWpSchwerpunkt(filter: { locale: { locale: { eq: $lang } } }) {
      nodes {
        slug
        title
      }
    }
  }
`
